import React from "react"

import Layout from "../components/layout"

import { graphql } from "gatsby"
import Image from "gatsby-image"
import About from "../components/about"
import Footer from "../components/footer"
import Jobs from "../components/jobs"
import SEO from "../components/seo"
import styled from "styled-components"

const StyledH4 = styled.h4`
  margin-top: 4rem;
  margin-bottom: 0rem;
  display: inline-block;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      margin-top: 2rem;
    }
`

export default function AboutPage({ data }) {
  return (
    <Layout>
      <SEO title="Über uns" />
      <About>
        <h4>{ data.ueber.edges[0].node.frontmatter.marker }</h4>
        <h2
          key={`h2`}
          id="___gatsby"
          dangerouslySetInnerHTML={{ __html: data.ueber.edges[0].node.frontmatter.einstieg }}
        />
        <p>{ data.ueber.edges[0].node.frontmatter.beschreibung }</p>        
        <div className="has-flex">
          <div className="w-half">
            <Image
              fluid={
                data.ueber.edges[0].node.frontmatter.bild.childImageSharp
                  .fluid
              }
            />
          </div>
        </div>

        <div css={`
          padding: 7rem 0rem 2.5rem 0rem;

          @media (max-width: ${props => props.theme.breakpoints.sm}) {
            padding: 2rem 0rem 1rem 0rem;
          }
        `}>
        <h4>{ data.ueber.edges[0].node.frontmatter.w_marker }</h4>
        <h2
          key={`h2`}
          id="___gatsby"
          dangerouslySetInnerHTML={{ __html: data.ueber.edges[0].node.frontmatter.t_schlagzeile }}
        />
        <p
          key={`p`}
          id="___gatsby"
          dangerouslySetInnerHTML={{ __html: data.ueber.edges[0].node.frontmatter.t_beschreibung }}
        />
        </div>

        <Image
              fluid={
                data.ueber.edges[0].node.frontmatter.teambild.childImageSharp
                  .fluid
              }
            />
        
        <StyledH4>{ data.ueber.edges[0].node.frontmatter.f_marker }</StyledH4>
        <p
          key={`p`}
          id="___gatsby"
          dangerouslySetInnerHTML={{ __html: data.ueber.edges[0].node.frontmatter.f_beschreibung }}
        />

        <Image
              fluid={
                data.ueber.edges[0].node.frontmatter.fuhrparkbild.childImageSharp
                  .fluid
              }
            />
      </About>
      <Jobs></Jobs>
      <Footer></Footer>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    ueber: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/ueber/"}}) {
      edges {
        node {
          frontmatter {
            marker
            einstieg
            beschreibung
            w_marker
            t_schlagzeile
            t_beschreibung
            f_marker
            f_beschreibung
            bild {
              childImageSharp {
                fluid(maxWidth: 625) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            teambild {
              childImageSharp {
                fluid(maxWidth: 625) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            fuhrparkbild {
              childImageSharp {
                fluid(maxWidth: 625) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
